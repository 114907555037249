<template lang="html">
  <li class="ListBigItem">
    <figure class="Item">
      <router-link
        v-if="item.slug"
        :to="{ name: 'artworks-details', params: { slug: item.slug } }"
        :title="item.title"
        class="picture"
      >
        <PreviewPicture :item="item" @loaded="show = true"/>
      </router-link>
      <PreviewPicture
        v-else
        class="picture"
        :item="item"
        @loaded="show = true"
      />

      <figcaption class="ListBigCaption">
        <h2 class="ListBigTitle">
          {{ item.title }}
        </h2>
        <time v-if="item.timeframe" class="ListBigCategory">
          {{ item.timeframe }}
        </time>
        <time class="ListBigDate" v-html="date" />
        <Content v-if="item.content" :content="item.content" />
        <div v-if="item.address" class="ListBigDetails address">
          <h3 :class="{ExternalLink:item.link}"  v-if="item.link">
            <a :href="item.link"
                title="Visit Website" 
                v-html="item.location" />
          </h3>
          <h3 v-else>{{ item.location }}</h3>
          <div v-html="address" v-if="!item.link"/>
          <a
            v-if="item.link"
            :href="item.link"
            title="Visit Website"
            v-html="address"
          />
        </div>
        <div v-if="item.cat == 'Photograph'">
          <div class="Spacer"> 
            &mdash;
          </div>
        <div class="Credits smaller">
        <span
          v-if="item.photographer && item.photographer != 'None'"
          class="CreditsPhotographer"
          >Photo: 
          {{ item.photographer }}
          </span>
      </div>
      </div>
      <button v-if="!item.address" @click="navigate(item)" class="linkProject">More Information</button>
      </figcaption>
    </router-link>
    </figure>

    <Bloomberg v-if="item.id == 5" />
  </li>
</template>

<script>
import Content from '@/components/Elements/Content.vue';
import PreviewPicture from '@/components/Container/Picture.vue';
import Bloomberg from '@/components/Bloomberg.vue';
import dayjs from 'dayjs';

export default {
  components: {
    Content,
    PreviewPicture,
    Bloomberg
  },
  props: {
    item: null,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    date() {
      if (this.item.date_from) {
        const date_from = dayjs(this.item.date_from).format('MMMM DD, YYYY');
        const date_until = dayjs(this.item.date_until).format('MMMM DD, YYYY');
        return date_from + '&ndash;' + date_until;
      } else return null;
    },
    address() {
      if (this.item.address) {
        const address = this.item.address.replace(/(?:\r\n|\r|\n)/g, '<br />');
        return address;
      } else return null;
    },
  },
  methods: {
    navigate(item) {
        this.$router.push({ name: 'artworks-details', params: { slug: item.slug } });
    }
  }

};
</script>

<style lang="css" scoped>
  .linkProject {
    background:white;
    color:black;
    border:1px solid #222727;
    padding:10px 12px;
    margin-top:24px;
    cursor:pointer;
    transition: all 0.5s;
    user-select:none;
  }

  .linkProject:hover {
      background:#222727;
      color:white;
  }
</style>