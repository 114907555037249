<template>
  <!-- BLOOMBERG -->
  <div class="bloomberg">
    <div class="btext">
      <strong>Bloomberg Connects App</strong>
      To hear more about L’Arc de Triomphe, Wrapped and other Realized Projects,
      download Bloomberg Connects, the free art and culture app.
    </div>

    <div class="bgrid">
      <div class="bicon">
        <img src="@/assets/img/icons/ChevronRight.png" />
      </div>
      <a
        href="https://apps.apple.com/de/app/bloomberg-connects/id1476456847"
        target="top"
      >
        Download for iOS
      </a>
    </div>

    <div class="bgrid">
      <div class="bicon">
        <img src="@/assets/img/icons/ChevronRight.png" />
      </div>

      <a
        href="https://play.google.com/store/apps/details?id=org.bloomberg.connects.docent"
        target="top"
      >
        Download for Android
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.bloomberg {
  background: #f3f3f3;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 1rem;
}

.bloomberg .btext {
  margin-bottom: 12px;
}

.bloomberg strong {
  display: block;
  margin-bottom: 12px;
}

.bloomberg a {
  font-weight: bold;
  font-size: 0.9rem;
  font-style: normal;
}

.bloomberg .bgrid {
  display: grid;
  grid-template-columns: 20px auto;
}

.bloomberg .bicon {
  position: relative;
  width: 20px;
  height: 24px;
}

.bloomberg .bicon img {
  position: relative;
  width: 40px;
  height: 40px;
  top: -8px;
  left: -12px;
}

@media only screen and (min-width: 768px) {
  .bloomberg {
    display: none;
  }
}
</style>
