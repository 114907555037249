<template>
  <div class="loader" ref="loader">
    <span class="dots">
      <div></div>
      <div></div>
      <div></div>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.loader {
  position: absolute;
  top: 0px !important;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222929;
}

.loader .dots {
  position: relative;
  top: calc(50% - 5px);
  left: calc(50% - 27px);
  display: grid;
  width: 54px;
  height: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
}

.loader div {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: white;
  animation-name: loading;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  -o-transform-origin: 50% 50%;
}

.loader div:nth-child(1) {
  animation-delay: 0.2s;
}

.loader div:nth-child(2) {
  animation-delay: 0.4s;
}

.loader div:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
</style>
