<template lang="html">
  <div v-if="pageData" class="ListBig">
    <Title spacer="true" />
    <section v-if="pageList">
      <ul>
        <Preview
          v-for="artwork in pageList"
          :item="artwork"
          :key="artwork.id"
        />
      </ul>
    </section>
  </div>
</template>

<script>
import Title from "@/components/Elements/Title.vue";
import Preview from "@/components/List/Preview.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Title,
    Preview,
  },
  computed: {
    ...mapGetters(["pageData", "pageList"]),
  },
  methods: {
    ...mapActions(["fetchItems"]),
  },
  created() {
    this.fetchItems({
      table: this.pageData.options.items.t,
      target: "setPageList",
      query: this.pageData.options.items.query,
    });
  },
};
</script>

<style lang="css">
.ListBigItem .Spacer {
  position: relative;
  top: -2px !important;
}

.ListBigItem .Credits {
  color: #333;
}

.ListBig .smaller {
  font-size: 0.6rem;
  line-height: 0.9rem;
}

.ListBig .CreditsPhotographer {
  display: block;
}

.ListBig .Image .proportion img {
  position: relative !important;
}

.ListBig .Content {
  display: block;
  margin-bottom: 0;
}

.ListBig .address {
  padding-top: 24px;
}

.ListBigItem {
  margin: 0;
}

.ListBigItem figure {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 36px;
}

.ListBigCategory,
.ListBigDate {
  display: block;
  margin-top: 3px;
  margin-bottom: 12px;
  color: #444;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
}

.ListBigTitle {
  display: block;
  margin-top: 9px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.4rem;
}

.ListBigDetails,
.ListBigDetails h3 {
  font-size: 0.8rem;
  line-height: 1.4rem;
}

.ListBigDetails h3 {
  font-weight: bold;
}

.ListBigItem img {
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  .smaller {
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
}

@media only screen and (min-width: 768px) {
  .ListBig .ListBigTitle {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .ListBigDetails {
    margin-top: 0;
  }

  .ListBig .Content {
    width: auto;
    margin-top: 24px;
    margin-bottom: 0;
    margin-right: 0;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ListBig li figure {
    display: grid;
    margin-bottom: 48px;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    place-items: center;
  }

  .ListBig li:nth-child(odd) .picture {
    width: 100%;
    height: 100%;
    grid-column-end: 8;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .ListBig li:nth-child(odd) figcaption {
    grid-column-end: 13;
    grid-column-start: 8;
    grid-row-start: 1;
  }

  .ListBig li:nth-child(even) .picture {
    width: 100%;
    height: 100%;
    grid-column-end: 13;
    grid-column-start: 6;
    grid-row-start: 1;
  }

  .ListBig li:nth-child(even) figcaption {
    grid-column-end: 6;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .ListBig li:nth-child(odd) img,
  .ListBig li:nth-child(even) img {
  }

  .Image .proportion {
    height: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .ListBig li figure {
    display: grid;
    margin-bottom: 60px;
  }

  .ListBig li:nth-child(odd) figcaption,
  .ListBig li:nth-child(even) figcaption {
    max-width: 440px;
    padding: 24px;
  }

  .Image img {
    object-fit: none;
    height: 100%;
  }

  .Image .proportion img {
    position: relative !important;
  }
}
</style>
