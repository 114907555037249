<template lang="html">
  <div class="Image">
    <div :class="{ proportion: item.proportion != 0 }" :style="padding">
      <picture :title="image.title">
        <template v-for="ext in exts">
          <source
            v-for="format in formats.sizes"
            :srcset="image + '-' + format.size + ext.ext"
            :media="
              '(min-width: ' +
                format.min +
                'px) and (max-width: ' +
                format.max +
                'px)'
            "
            :type="ext.mimetype"
          />
        </template>

        <img
          :src="image + '-100.jpg'"
          class="image"
          :class="{ show: loaded }"
          :alt="image.title"
          @load="loaded = true"
        />
      </picture>
      <Preloader v-if="!loaded" />
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Elements/Preloader.vue";

export default {
  components: { Preloader },
  props: {
    item: null,
  },
  data() {
    return {
      loaded: false,
      show: false,
      debug: false,
      exts: [
        { ext: ".webp", mimetype: "image/webp" },
        { ext: ".jpg", mimetype: "image/jpeg" },
      ],
      formats: {
        sizes: [
          { size: "400", min: 250, max: 400 },
          { size: "800", min: 400, max: 800 },
          { size: "1200", min: 800, max: 1200 },
          { size: "1600", min: 1200, max: 1600 },
          { size: "2800", min: 1600, max: 9000 },
        ],
      },
    };
  },
  computed: {
    image() {
      if (this.item.image.image)
        return "/src/" + this.item.image.image.split("-")[0];
      else return "/src/" + this.item.image.split("-")[0];
    },
    padding() {
      if (this.item.proportion != 0) {
        if (this.item.proportion == 107) this.item.proportion = 106.2;
        return "padding-bottom: " + this.item.proportion + "%;";
      } else return null;
    },
  },
  mounted() {
    if (this.debug)
      console.log("Container: Image Preview created", this.item.image.id);
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.Image {
  position: relative;
  user-select: none;
}

.Image img {
  display: block !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.Image .proportion img {
  position: absolute !important;
  top: 0;
}

.Image img.show {
  opacity: 1;
}
</style>
